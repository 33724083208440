import React from 'react';
import Loader from 'react-loader-spinner';
import i18n from 'i18next';

import './style.css';

const CustomLoader = props => {
  const { error, retry } = props;

  const handleRetry = () => {
    window.location.reload(true);
    retry();
  };

  if (error) {
      console.log('🤦‍♀️', error);
    return (
      <div className="loader__error">
        {i18n.t('notFound:trouble')}, &#160;
        <span className="loader__try-again" onClick={handleRetry}>
          {i18n.t('notFound:tryAgain')}
        </span>
        .
      </div>
    );
  }

  const styles = {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
    zIndex: 1000
  };

  return (
    <div style={styles}>
      <Loader
        type="Puff"
        color={props.color || getComputedStyle(document.body).getPropertyValue('--bgMain')}
        height="100"
        width="100"
      />
    </div>
  );
};

export default CustomLoader;
