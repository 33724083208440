import React, { Component } from "react";

import { Card } from "@material-ui/core";

import mapStore from "../../../Stores/MapStore";
import UIStore from "../../../Stores/UIStore";

import { Button } from "reactstrap";

import { observer } from "mobx-react";
import ReceiptStore from "../../../Stores/ReceiptStore";
import { Container } from "reactstrap";
import "./style.scss";
import Payment from "../../../Components/ProfileComponents/Payment";


@observer
class ReceiptsPage extends Component {

  constructor(props) {
    super(props);
    this.state ={
      isReceiptModal: false,
      receiptnumber: 0,
      receiptType: '',
    }
  }

  async componentDidMount() {
    await ReceiptStore.getReceiptsList();
  }

  closeReceipt = () => {
    this.setState({
      isReceiptModal: false,
    })
  }

  getReceiptHandler = (number, type) => {
    this.setState({
      isReceiptModal: true,
      receptnumber: number,
      receiptType: type,
    })
  }

  render() {
    const { hasReceipts, receiptsList } = ReceiptStore;

    return (
        <Container fluid>
          <div class="container receipts">
          {hasReceipts ? (
            <table class="receipts">
              <thead>
                <tr>
                  <th>Дата формирования</th>
                  <th>Идентификатор</th>
                  <th>Сумма</th>
                  <th>Статус</th>
                  <th>Назначение</th>
                </tr>
              </thead>
              <tbody>
                {receiptsList.map((r) => (
                  <>
                    <tr class="receipt-card" key={r.UIN}>
                      <td>{r.date}</td>
                      <td>{r.receiptnumber}</td>
                      <td>{r.sum}</td>
                      <td class="nowrap">{r.status}</td>
                      <td>
                        {r.receiptname}
                      </td>
                      <td>
                        <span className="my-3">
                          <Button
                            classname="my-3"
                            outline
                            onClick={() => this.getReceiptHandler(r.receiptnumber, r.type)}
                          >{(r.type == 'legal') ? ('Счет') : ('Квитанция')}</Button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </>
              ))}
              </tbody>
            </table>
          ) : (
            <div>Квитанции отстсутствуют</div>
          )}
          {!this.state.isReceiptModal ? (
            <></>
          ) : (
            <Payment
              actionType="receipt"
              isOpen={true}
              onSubmit={this.closeReceipt}
              onExit={this.closeReceipt}
              isGetReceipt={this.state.isReceiptModal}
              receptnumber={this.state.receptnumber}
              receiptType={this.state.receiptType}
              startDisabled={false}
            />
          ) }
          </div>
        </Container>
    );
  }
}

export default ReceiptsPage;
