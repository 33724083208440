const OBJECT_TYPES = {
  PARKINGS: 'parkings',
  RESIDENTZONE: 'residentzone',

  PARKOMATS: 'parcomats',
  TERMINALS: 'terminals',
  INFORMATIONTABLE: 'InformationTable',
  CHURCH: 'Church',
  MUSEUM: 'Museum',
  SIGHT: 'Sight',
};

export default OBJECT_TYPES;
